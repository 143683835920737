import React, { useEffect, useRef } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "./House.scss";

export default function House() {
  const rightAnim = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const handleImageVisibility = (opacity) => {
      if (imageRef.current) {
        imageRef.current.style.opacity = opacity;
      }
    };

    const handleAnimationIteration = () => {
      handleImageVisibility("0");

      setTimeout(() => handleImageVisibility("1"), 3500);
      setTimeout(() => handleImageVisibility("0"), 6500);
    };

    const rightElement = rightAnim.current;
    rightElement.addEventListener(
      "animationiteration",
      handleAnimationIteration
    );

    setTimeout(() => handleImageVisibility("1"), 3500);
    setTimeout(() => handleImageVisibility("0"), 6500);

    return () => {
      rightElement.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);

  return (
    <div className="homeHeader">
      <div className="container0 homeContainer">
        <section className="home">
          <h5 className="animationLeft">YOU DREAM</h5>
          <div className="homeImgs">
            <h6 className="animationRight" ref={rightAnim}>
              <img
                // src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/WE DESIGN.svg"
                src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/WE DESIGN.png"
                alt="WE DESIGN"
              />
            </h6>

            <span>
              <img
                ref={imageRef}
                src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/YourReality.svg"
                alt="Your Reality"
              />
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}
