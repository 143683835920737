// import img1 from "./../../../assets/about/ourClients/image 19.svg";
// import img2 from "./../../../assets/about/ourClients/image 20.svg";
// import img3 from "./../../../assets/about/ourClients/INVASSO-GRAY.svg";

// import imgHover1 from "./../../../assets/about/ourClients/image 192.svg";
// import imgHover2 from "./../../../assets/about/ourClients/image 202.svg";
// import imgHover3 from "./../../../assets/about/ourClients/INVASSO-.svg";

const DataEngineering = [
  {
    id: 1,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/image 19.svg',
    imgHover: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/image 192.svg',
  },
  {
    id: 2,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/image 20.svg',
    imgHover: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/image 202.svg',
  },
  {
    id: 3,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/INVASSO-GRAY.svg',
    imgHover: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/about/INVASSO-.svg',
  },
  // {
  //   id: 4,
  //   img: img4,
  // },
];
export default DataEngineering;
